// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import MiscPageWrapper from '../components/misc-page-wrapper'
import '../components/misc-page-wrapper/style.less'

import Link from '../components/link'
import '../components/link/style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React
const pageSchema = {
  title: 'About',
  slug: 'about',
  abstract: 'About',
  breadcrumbs: [
    { title: 'Auroville Archives', slug: '' },
    { title: 'About', slug: '/about' },
  ],
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    logo: file(relativePath: { eq: "logos/logo.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = (props) => (
  <MiscPageWrapper className="" pageSchema={pageSchema} {...props}>
    <h1>About Auroville Archives</h1>
    <p className="justify">
      Auroville Archives began with one Aurovilian, Maj. Gen. Krishna Tewari
      (Retd.), setting the intention to collect and preserve for posterity the
      documents created in and about Auroville.
    </p>
    <p className="justify">
      In 1991 a space in the basement of Bharat Nivas began to house this
      growing collection. A team was formed to conduct the work of collecting
      and organizing the materials and assisting community members who wished to
      learn about the history of Auroville.
    </p>
    <p className="justify">
      Beginning with collections of paper documents, photos, slides and
      cassettes, the Archives over the years saw the emergence of new
      technologies – computers and software, digital photography, CDs and CDRs,
      digital videography, internet – and along with these the tools and methods
      of converting the physical and analog content into digital. Efforts to
      catalog the collection also evolved with the changing technology.
    </p>
    <p className="justify">
      Construction of a specially-designed building for the Archives started in
      2015, finished in part in 2017 and now houses the Archives collections,
      research library, office and (to open in the near future) reading room for
      researchers. As is the case for all archives, the Auroville Archives is
      not a lending library: books and documents can be consulted on the spot or
      may be scanned and shared.{' '}
    </p>
    <p className="justify">
      Today the Archives collections include paper documents and their digital
      counterparts, photographs and slides and their digital counterparts,
      videos from the past converted into digital formats, digital-native
      materials (PDF documents, recent photos, recent videos), books, maps,
      magazines and artifacts.
    </p>
    <p className="justify">
      The Archives collections are constantly growing as we move forward in
      time, as Auroville continues to unfold, develop, breathe and question, in
      the inevitable (if gradual) strengthening of its identity.
    </p>
    <h2>Team and office</h2>
    <p className="justify">
      <strong>Archives team:</strong> Gilles Guigan, D. Thambidurai, Doris Van
      Kalker, Meera Elumalai, Kristen Gravelle, Isabelle Mollet
    </p>
    <p className="justify">
      <strong>Associates:</strong> Pranav Kumar (website), Marco Saroldi
      (photographer)
    </p>
    <p className="justify">
      The Archives building is located in the Administrative Area of Auroville,
      next to SAIIER, near Town Hall.
    </p>
    <p className="justify">
      Our office hours are 8:30-12:30 and 2:00-4:30, Monday to Friday, plus
      Saturday mornings 8:30-12:30. Please email us for an appointment. We also
      answer queries directly by email.
    </p>
    <p>avarchives@auroville.org.in</p>
    <h2>Contributing materials to the Archives</h2>
    <p className="justify">
      We welcome receiving documents, photos, ephemera, artifacts – any
      materials that may add richness to our collections.
    </p>
    <p className="justify" className="justify">
      We are also interested in hearing your stories about life in Auroville –
      your personal experiences of events, and what has been important to you in
      your life here. We can either receive these in a finished form (writing,
      video, audio), or together we can produce something by recording your
      reflections and memories.
    </p>
  </MiscPageWrapper>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
